import { useEffect, useRef, useState } from 'react';
import './../App.css';
import emailjs from '@emailjs/browser';
import inputData from './../modules/inputData/inputData'
import Request_1_a from './Form/Request_1_a';
import Request_1_b from './Form/Request_1_b';
import Request_2_a from './Form/Request_2_a';
import Request_2_b from './Form/Request_2_b';
import Request_3 from './Form/Request_3';
import Request_4 from './Form/Request_4';
import Request_5 from './Form/Request_5';
import Request_6 from './Form/Request_6';
import Request_7 from './Form/Request_7';
import Law from './Law';
import { useNavigate } from 'react-router-dom';



export default function Intro(){
    const navigate = useNavigate();

    // Change Object
    let [dataObj, setDataObj] = useState({});

    // Choose subjects
    const [checkItems, setCheckItems] = useState([]);
    const selectChecked = (checked, value) => {
        if (checked) {
          setCheckItems(item => [...item, value]); 
        } else {
          setCheckItems(checkItems.filter((el) => el !== value));
        }
    };

    // Email-JS
    const form = useRef();
    const sendEmail = e => {
        e.preventDefault();

        emailjs.sendForm("service_qig6evt", "template_otkt6b6", form.current, "iYpDNITssEIVuwvh_").then(
        result => {
            alert("의뢰서 전송이 완료되었습니다. 다음 단계를 진행해주세요.");
            form.current.reset();
            navigate('/upload')
            window.location.reload()

        },
        error => {
            console.log(error);
            alert("이메일이 전송이 실패되었습니다.");
        },
        );
    };


    return(
        <>
            <div className='intro'>
                <form className='form' ref={form} onSubmit={sendEmail}>
                    {/* require 사용해서 빈칸 없애기 */}
                    <div className='form-header'>
                        <ul className='form-header__logo'>
                            <li style={{color:'red'}}>
                                <img src='/images/logo.png' />
                            </li>
                            <li>가설 구조물 도면 설계 전문회사<br/>JH시스템</li>
                        </ul>
                        <ul>
                            <li>의뢰 가능 종류 : 도면 설계, 견적용 물량도, 구조검토, 기성도면</li>
                        </ul>
                        <ul>
                            <li>의뢰 접수 시스템</li>
                        </ul>
                        <ul>
                            <li>업체명(의뢰자)</li>
                            <li>
                                <label>
                                    <input
                                        type="text"
                                        name="업체명"
                                        placeholder="공란을 채워주세요"
                                        required
                                    />
                                </label>
                            </li>
                        </ul>
                        <ul>
                            <li>연락처</li>
                            <li>
                                <label>
                                    <input
                                        type="text"
                                        name="연락처"
                                        placeholder="공란을 채워주세요"
                                        required
                                    />
                                </label>
                            </li>
                        </ul>
                        <ul>
                            <li>현장명</li>
                            <li>
                                <label>
                                    <input
                                        type="text"
                                        name="현장명"
                                        placeholder="공란을 채워주세요"
                                        required
                                    />
                                </label>
                            </li>
                        </ul>
                        <ul>
                            <li>ID(보내는 사람 메일주소)</li>
                            <li>
                                <label>
                                    <input
                                        type="text"
                                        name="user_email"
                                        placeholder="공란을 채워주세요"
                                        required
                                    />
                                </label>
                            </li>
                        </ul>
                        <ul className='form-law'>
                            <li>개인정보처리방침</li>
                            <li>
                                <label>
                                    <input
                                    type="checkbox"
                                    name="?"
                                    onChange={(e)=>{inputData(setDataObj, '개인정보', e.target.value)}}
                                    style={{width:"auto"}}
                                    required
                                    /> 동의
                                </label>
                                {/* <span>[약관보기]</span> */}
                                <Law />
                            </li>
                        </ul>
                        <ul className='form-header__request'>
                            <li>의뢰종류</li>
                            <div>
                                <ul>
                                    <li>도면설계 / 견적용 물량도</li>
                                    <div>
                                        <li>
                                            <label>
                                                <input
                                                type="checkbox" name="의뢰종류_1_a" onChange={(e) => selectChecked(e.target.checked, 'request_1_a')}
                                                /> 동바리
                                            </label>
                                        </li>
                                        <li> 
                                            <label>
                                                <input type="checkbox" name="의뢰종류_1_b" onChange={(e) => selectChecked(e.target.checked, 'request_1_b')} /> 비계
                                            </label>
                                        </li>
                                    </div>
                                </ul>

                                {/* <ul>
                                    <li>도면설계</li>
                                    <div>
                                        <li>
                                            <label>
                                                <input type="checkbox" name="의뢰종류_2_a" onChange={(e) => selectChecked(e.target.checked, 'request_2_a')} /> 동바리
                                            </label>
                                        </li>
                                        <li>
                                            <label>
                                                <input type="checkbox" name="의뢰종류_2_b" onChange={(e) => selectChecked(e.target.checked, 'request_2_b')} /> 비계
                                            </label>
                                        </li>
                                    </div>
                                </ul> */}

                                <li>
                                    <label>
                                        <input type="checkbox" name="의뢰종류_3" onChange={(e) => selectChecked(e.target.checked, 'request_3')} /> 구조 검토
                                    </label>
                                </li>
                                {/* <li>
                                    <label>
                                        <input type="checkbox" name="의뢰종류_4" onChange={(e) => selectChecked(e.target.checked, 'request_4')} /> 수량 산출
                                    </label>
                                </li>
                                <li>
                                    <label>
                                        <input type="checkbox" name="의뢰종류_5" onChange={(e) => selectChecked(e.target.checked, 'request_5')} /> 도면 수정
                                    </label>
                                </li> */}
                                {/* <li>
                                    <label>
                                        <input type="checkbox" name="의뢰종류_6" onChange={(e) => selectChecked(e.target.checked, 'request_6')} /> 기성 도면
                                    </label>
                                </li> */}
                                <li>
                                    <label>
                                        <input type="checkbox" name="의뢰종류_7" onChange={(e) => selectChecked(e.target.checked, 'request_7')} /> 자동화 설계 프로그램 구매 문의, 오류 신고
                                    </label>
                                </li>
                            </div>
                        </ul>
                    </div>

                    {checkItems.includes('request_1_a') ? <Request_1_a checkItems={checkItems} /> : ''}
                    {checkItems.includes('request_1_b') ? <Request_1_b checkItems={checkItems} /> : ''}
                    {/* {checkItems.includes('request_2_a') ? <Request_2_a checkItems={checkItems} /> : ''}
                    {checkItems.includes('request_2_b') ? <Request_2_b checkItems={checkItems} /> : ''} */}
                    {checkItems.includes('request_3') ? <Request_3 checkItems={checkItems} /> : ''}
                    {/* {checkItems.includes('request_4') ? <Request_4 checkItems={checkItems} /> : ''}
                    {checkItems.includes('request_5') ? <Request_5 checkItems={checkItems} /> : ''} */}
                    {checkItems.includes('request_6') ? <Request_6 checkItems={checkItems} /> : ''}
                    {checkItems.includes('request_7') ? <Request_7 checkItems={checkItems} /> : ''}

                    {/* <Request_1_a checkItems={checkItems} /> */}
                    {/* <Request_1_b checkItems={checkItems} /> */}
                    {/* <Request_2_a checkItems={checkItems} /> */}
                    {/* <Request_2_b checkItems={checkItems} /> */}
                    {/* <Request_3 checkItems={checkItems} /> */}
                    {/* <Request_4 checkItems={checkItems} /> */}
                    {/* <Request_5 checkItems={checkItems} /> */}
                    {/* <Request_6 checkItems={checkItems} /> */}
                    {/* <Request_7 checkItems={checkItems} /> */}

                    <div className='form-header form-submit'>
                        <input type="submit" value="전송하기" />
                    </div>

                    <div className='form-header'>
                        <ul>
                            <li>회사 대표 연락처</li>
                            <li>010-5762-1829</li>
                        </ul>
                        <ul>
                            <li>회사 대표 이메일 주소</li>
                            <li>jhsystem@jhsystem.by-works.com</li>
                        </ul>
                        <ul>
                            <li>회사 홈페이지 주소</li>
                            <li><a href='https://jhsystem.clickn.co.kr' target='_blank'>https://jhsystem.clickn.co.kr</a></li>
                        </ul>
                    </div>
                </form>
            </div>
        </>
    )
}